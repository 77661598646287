<template>
  <section v-if="Object.keys(item).length" class="itemView" ref="itemView">
    <!-- Backdrop -->
    <div class="itemView__header" :style="[item.backdrop_path ? { 'background-image': `url('https://image.tmdb.org/t/p/original/${item.backdrop_path}')` } : { 'background-image': 'none' }]">
      <figure class="posterGroup" v-if="item.poster_path">
        <!-- Poster -->
        <picture>
          <img :src="`https://image.tmdb.org/t/p/w300/${item.poster_path}`" :alt="`Cover art for ${item.title}`">
        </picture>
        <!-- Action: Watch -->
        <button type="button" @click="watch">Watch</button>
      </figure>

      <div class="details">
        <!-- Title -->
        <h2 v-if="item.title">{{ item.title }}</h2>

        <!-- Metadata -->
        <div class="crumbs">
          <!-- Certification -->
          <p v-if="certification" class="certification">{{ certification }}</p>
          <!-- Release Date -->
          <p v-if="item.release_date">{{ item.release_date.split('-')[0] }}</p>
          <span v-if="item.release_date">&#183;</span>
          <!-- Genres -->
          <div v-if="item.genres" class="genres">
            <p v-for="(genre, index) in item.genres" :key="index">{{ genre.name }}
              <span v-if="index + 1 < item.genres.length"><p>,</p></span>
            </p>
          </div>
          <!-- Runtime -->
          <span v-if="item.runtime">&#183;</span>
          <p v-if="item.runtime">{{ runtime }}</p>
        </div>

        <!-- Tagline -->
        <h3 v-if="item.tagline">{{ item.tagline }}</h3>

        <div class="trailerGroup">
          <!-- Popularity Score -->
          <score v-if="item.vote_average" :score="item.vote_average" />
          <!-- Action: Manage Watchlist -->
          <span
            v-if="movieWatchlist.includes(item.id)"
            class="action-icon is-active"
            tooltip="Remove from watchlist"
            @click="updateWatchlist({ operation: 'remove', category: 'movieWatchlist', type: 'movies', item })">
            <img src="@/assets/icons/watchlist-on.svg" alt="">
          </span>
          <!-- If Limit Reached -->
          <span
            v-else-if="movieWatchlist.length == 25"
            class="action-icon not-active"
            tooltip="Limit reached">
            <img src="@/assets/icons/watchlist-off.svg" alt="">
          </span>
          <span
            v-else
            class="action-icon not-active"
            tooltip="Add to watchlist"
            @click="updateWatchlist({ operation: 'add', category: 'movieWatchlist', type: 'movies', item })">
            <img src="@/assets/icons/watchlist-off.svg" alt="">
          </span>
          <!-- Action: Manage Favorites -->
          <span
            v-if="favoriteMovies.includes(item.id)"
            class="action-icon is-active"
            tooltip="Remove from favorites"
            @click="updateFavorites({ operation: 'remove', category: 'favoriteMovies', type: 'movies', item })">
            <img src="@/assets/icons/favorite-fill.svg" alt="">
          </span>
          <!-- If Limit Reached -->
          <span
            v-else-if="favoriteMovies.length == 10"
            class="action-icon not-active"
            tooltip="Limit reached">
            <img src="@/assets/icons/favorite-outline.svg" alt="">
          </span>
          <span
            v-else
            class="action-icon not-active"
            tooltip="Add to favorites"
            @click="updateFavorites({ operation: 'add', category: 'favoriteMovies', type: 'movies', item })">
            <img src="@/assets/icons/favorite-outline.svg" alt="">
          </span>
          <!-- Action: Manage Watched Lists -->
          <span
            v-if="watched.movies.includes(item.id)"
            class="action-icon is-active"
            @click="updateWatchedLists({ operation: 'remove', type: 'Movies', item })">
            <img src="@/assets/icons/watched.svg" alt="">
          </span>
          <span
            v-else
            class="action-icon not-active"
            tooltip="Watched"
            @click="updateWatchedLists({ operation: 'add', type: 'Movies', item })">
            <img src="@/assets/icons/not-watched.svg" alt="">
          </span>
          <!-- Action: Play Trailer -->
          <span
            v-if="trailer"
            class="action-icon trailer"
            tooltip="View trailer"
            @click="toggleModal">
            <img src="@/assets/icons/view-trailer.svg" alt="">
          </span>
        </div>

        <!-- Overview -->
        <h4 v-if="item.overview">Overview</h4>
        <p v-if="item.overview" class="overview">{{ item.overview }}</p>

        <!-- Team -->
        <div class="crew">
          <div class="crew__member" v-for="(company, index) in item.production_companies" :key="index">
            <p>{{ company.name }}</p>
            <span>Production</span>
          </div>
          <div class="crew__member" v-if="director">
            <p>{{ director.name }}</p>
            <span>Director</span>
          </div>
          <div class="crew__member" v-if="producer">
            <p>{{ producer.name }}</p>
            <span>Producer</span>
          </div>
          <div class="crew__member" v-if="writer">
            <p>{{ writer.name }}</p>
            <span>Writer</span>
          </div>
        </div>

      </div>
    </div>

    <!-- Cast -->
    <div v-if="item.credits.cast.length" class="horizontal-wrapper">
      <poster
        v-for="(person, index) in item.credits.cast"
        :key='index'
        :item='person'
        design='poster'
        type='person'
      />
    </div>

    <!-- Recommendations -->
    <template v-if="item.recommendations.results.length">
      <p class="recommendations">You might also like</p>
      <div class="horizontal-wrapper">
        <poster
          v-for="(film, index) in item.recommendations.results"
          :key='index'
          :item='film'
          design='poster'
          type='movie'
        />
      </div>
    </template>
  </section>
</template>

<script>
  import api from '@/api'
  import { defineAsyncComponent } from 'vue'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { getAnalytics, logEvent } from "firebase/analytics";

  export default {
    name: 'Movie',
    data: () => ({
      item: {}
    }),
    components: {
      Poster: defineAsyncComponent(() => import('@/components/poster' /* webpackChunkName: 'Poster' */)),
      Score: defineAsyncComponent(() => import('@/components/score' /* webpackChunkName: 'Score' */))
    },
    computed: {
      ...mapState('user', ['user', 'watched']),
      ...mapGetters('user', { favoriteMovies: 'getFavoriteMovies', movieWatchlist: 'getMovieWatchlist' }),

      runtime() {
        let time = this.item.runtime
        let hours = (time / 60)
        let rhours = Math.floor(hours)
        let minutes = (hours - rhours) * 60
        let rminutes = Math.round(minutes)
        return `${rhours}h ${rminutes}m`
      },
      certification() {
        return this.item.release_dates?.results.find(x => x.iso_3166_1 === 'US')?.release_dates[0].certification
      },
      trailer() {
        return this.item.videos?.results.find(x => x.name === 'Official Trailer') || this.item.videos?.results.find(x => x.type === 'Trailer')
      },

      director() {
        return this.item.credits?.crew.find(x => x.known_for_department === 'Directing' && x.job === 'Director')
      },

      producer() {
        return this.item.credits?.crew.find(x => x.known_for_department === 'Production' && x.job === 'Producer')
      },

      writer() {
        return this.item.credits?.crew.find(x => x.known_for_department === 'Writing' && x.job === 'Writer')
      }
    },
    activated() {
      // Watch for route id
      let removeWatcher = this.$watch('$route.query.id', (id) => {
        // Reset item
        this.item = {}
        // Request item on desired route
        if (this.$route.name === 'Movie') this.getItem(id)
        // Remove watcher
        else removeWatcher()
      }, { immediate: true })
    },
    methods: {
      ...mapActions('user', ['updateFavorites', 'updateWatchlist', 'updateWatchedLists']),
      ...mapActions('utilities', ['logActivity']),

      getItem(id) {
        api.tmdb.reqItem('film', 'movie', id).then(res => {
          this.item = res.data
        })
      },
      async watch() {
        await logEvent(getAnalytics(), 'watched_movie', {
          username: this.user.username,
          item: this.item.title
        })

        if (this.item.imdb_id) {
          this.$router.push({ name: 'Watch', query: { type: 'movie', id: this.$route.query.id, tag: this.item.imdb_id }})
        } else {
          this.$router.push({ name: 'Watch', query: { type: 'movie', id: this.$route.query.id }})
        }
        // Log activity in database
        this.logActivity({
          created: new Date().getTime(),
          activity: 'Watched movie',
          item: this.item.title,
          user: { name: this.user.username, id: this.user.id }
        })
      },
      toggleModal() {
        this.$store.commit('utilities/TOGGLE_MODAL', { type: 'trailer', data: this.trailer.key })
      }
    },
    deactivated() {
      this.$store.commit('utilities/TOGGLE_MODAL')
    }
  }
</script>
